import React, { Component } from 'react';
import PropTypes from 'prop-types';

class ShowMore extends Component {
  constructor() {
    super();

    this.handleClick = this.handleClick.bind(this);
    this.state = {
      showMore: false
    };
  }

  handleClick(e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      showMore: !this.state.showMore
    });
  }

  render() {
    return (
      <div
        onClick={this.handleClick}
        className={`show-more ${
          this.state.showMore ? 'show-more--expanded' : ''
        }`}
        dangerouslySetInnerHTML={{
          __html: this.state.showMore
            ? this.props.data
            : `<p class="show-more__text">${this.props.initialText}</p>`
        }}
      />
    );
  }
}

ShowMore.defaultProps = {
  initialText: 'Show More'
};

ShowMore.propTypes = {
  initialText: PropTypes.string,
  data: PropTypes.string.isRequired
};

export default ShowMore;
