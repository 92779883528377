import React, { Component } from 'react';
import Link from 'react-router-dom/Link';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import striptags from 'striptags';
import ShowMore from '../show_more/show_more';

function processAssetServerImage(
  imgSrc,
  placeholder = '//images.fastcompany.net/image/upload/w_387,h_218,c_fill,q_auto,f_auto,fl_lossy,o_30/v1498077352/fcweb/ibd_a2ubg2.jpg'
) {
  let updatedImgSrc = imgSrc;

  // https://fast-company-res.cloudinary.com/image/fetch/w_599,q_auto,f_auto,fl_lossy/https://a.fastcompany.net/multisite_files/fastcompany/people/2017/11/21/p-1-dazzleship-el6sdb.jpg

  if (typeof imgSrc !== 'string' || get(imgSrc, 'length') === 0) {
    updatedImgSrc = placeholder;
  }

  return `https://a.fastcompany.net/image/fetch/w_599,ar_16:9,c_fill,q_auto,f_auto,fl_lossy/https:${updatedImgSrc}`;
}

export default class IBDCategory extends Component {
  shouldComponentUpdate(nextProps) {
    return this.props.active !== nextProps.active;
  }

  render() {
    const { category, index, year, active, slug, slugParam } = this.props;
    const categoryName = get(category, 'category_name');
    const url = get(category, 'url');
    const judges = get(category, 'judges_people');
    const list = get(category, 'products.list');
    const winners = get(category, 'products.winners');
    const finalists = get(category, 'products.finalists');
    const mentions = get(category, 'products.mentions', []);
    const productFieldToDisplay = year >= 2017 ? 'description' : 'why';
    const param = slug === 'gift-guide' ? slugParam : year;
    const link = active
      ? `/${slug}/${param}`
      : `/${slug}/${param}/category/${url}`;

    return (
      <section
        className={`ibd-category ibd-category--${index} ${
          active ? 'ibd-category--active' : ''
        }`}
      >
        <div className={`ibd-category__row ibd-category__row--${index}`}>
          <Link
            className={`ibd-category__header ${
              active ? 'ibd-category__header--active' : ''
            }`}
            to={link}
          >
            <h2 className="ibd-category__title">{categoryName}</h2>
            <img
              className={`ibd-category__title-icon
              ${active ? ' ibd-category__title-icon--active' : ''}`}
              src="//images.fastcompany.net/image/upload/q_auto,fl_lossy/v1495723987/arrow-list-down-fix.svg"
              alt="arrow icon"
              title="arrow icon"
            />
          </Link>
          {active ? (
            <div className="ibd-category__dek">
              <div className="ibd-category__category-description">
                {striptags(category.category_description || '')}
              </div>
              {get(judges, 'length') ? (
                <div className="ibd-category__judges">
                  <span className="bold">Judges:</span>{' '}
                  {judges.map(judge => judge.name).join(', ')}
                </div>
              ) : (
                ''
              )}
            </div>
          ) : (
            ''
          )}
        </div>
        {active ? (
          <div
            className={`ibd-category__accordion ibd-category__accordion--${index}`}
          >
            {get(list, 'length') ? (
              <div className="ibd-category__accordion-section ibd-category__accordion-section--list">
                {list.map(product => (
                  <div className="ibd-category__product-item">
                    <Link to={`/gift/${product.url}`}>
                      <figure className="ibd-category__product-poster ibd-category__product-poster--item">
                        <img
                          alt={product.name}
                          src={processAssetServerImage(get(product, 'image'))}
                        />
                      </figure>
                      <h4 className="ibd-category__product-name ibd-category__product-name--item">
                        {product.name}
                      </h4>
                      <h4 className="ibd-category__product-description ibd-category__product-description--item">
                        {striptags(product.description)}
                      </h4>
                    </Link>
                    <div className="ibd-category__product-price">
                      <span>${product.price}</span>
                      <a href={product.affiliate_link} target="_blank">
                        <button>Buy Now</button>
                      </a>
                    </div>
                  </div>
                ))}
              </div>
            ) : null}

            {get(winners, 'length') ? (
              <div className="ibd-category__accordion-section ibd-category__accordion-section--winners">
                <h3 className="ibd-category__accordion-section-title">
                  <span>Winners</span>
                </h3>
                {winners.map(winner => (
                  <div
                    className="ibd-category__product-winner"
                    key={winner.url}
                  >
                    <figure className="ibd-category__product-poster ibd-category__product-poster--winner">
                      <img
                        alt={winner.name}
                        src={processAssetServerImage(get(winner, 'image'))}
                      />
                    </figure>
                    <h4 className="ibd-category__product-name ibd-category__product-name--winner">
                      {winner.name}
                    </h4>
                    {winner[productFieldToDisplay] ? (
                      <h4 className="ibd-category__product-description ibd-category__product-description--winner">
                        {striptags(winner[productFieldToDisplay])}
                      </h4>
                    ) : (
                      ''
                    )}
                    {winner.body ? (
                      <div className="ibd-category__credit">
                        <ShowMore
                          initialText="See Credits"
                          data={winner.body}
                        />
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                ))}
              </div>
            ) : null}

            {get(finalists, 'length') ? (
              <div className="ibd-category__accordion-section ibd-category__accordion-section--finalists">
                <h3 className="ibd-category__accordion-section-title">
                  <span>Finalists</span>
                </h3>
                {finalists.map(product => (
                  <div
                    className="ibd-category__product-finalists-and-mentions"
                    key={product.url}
                  >
                    <figure className="ibd-category__product-poster">
                      <img
                        alt={product.name}
                        src={processAssetServerImage(get(product, 'image'))}
                      />
                    </figure>
                    <h4 className="ibd-category__product-name">
                      {product.name}
                    </h4>
                    {product[productFieldToDisplay] ? (
                      <h4 className="ibd-category__product-description">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: striptags(product[productFieldToDisplay])
                          }}
                        />
                      </h4>
                    ) : (
                      ''
                    )}

                    {product.body ? (
                      <div className="ibd-category__credit">
                        <ShowMore
                          initialText="See Credits"
                          data={product.body}
                        />
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                ))}
              </div>
            ) : null}

            {get(mentions, 'length') ? (
              <div className="ibd-category__accordion-section ibd-category__accordion-section--mentions">
                <h3 className="ibd-category__accordion-section-title">
                  <span>Honorable Mentions</span>
                </h3>
                {mentions.map(product => (
                  <div
                    className="ibd-category__product-finalists-and-mentions"
                    key={product.url}
                  >
                    <figure className="ibd-category__product-poster">
                      <img
                        alt={product.name}
                        src={processAssetServerImage(get(product, 'image'))}
                      />
                    </figure>
                    <h4 className="ibd-category__product-name">
                      {product.name}
                    </h4>
                    {product[productFieldToDisplay] ? (
                      <h4 className="ibd-category__product-description">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: striptags(
                              striptags(product[productFieldToDisplay])
                            )
                          }}
                        />
                      </h4>
                    ) : (
                      ''
                    )}

                    {product.body ? (
                      <div className="ibd-category__credit">
                        <ShowMore
                          initialText="See Credits"
                          data={product.body}
                        />
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                ))}
              </div>
            ) : (
              ''
            )}
          </div>
        ) : (
          ''
        )}
      </section>
    );
  }
}

IBDCategory.defaultProps = {
  slug: 'innovation-by-design',
  year: '',
  slugParam: ''
};

IBDCategory.propTypes = {
  category: PropTypes.shape({}).isRequired,
  index: PropTypes.string.isRequired,
  year: PropTypes.string,
  active: PropTypes.bool.isRequired,
  slug: PropTypes.string,
  slugParam: PropTypes.string
};
